.form-input {
    display: flex;
    flex-direction: column;
    width: 100%;
}

form input, textarea {
    padding: 8px;
    margin: 8px 0px;
    border-radius: 5px;
    border: 1px solid gray;
}

form textarea {
    font-size: 20px !important;
}

form label {
    color: gray;
}

form span {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"] {
    border: 2px solid red;
}

input:invalid[focused="true"] ~ span {
    display: block;
}
