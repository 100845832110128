body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .meta-tag-list {
    display: block;
    padding-top: 16px;
  }

  .tag-list {
    padding: 0;

  } 

  .tag-list > li {
    width: fit-content;
    margin-left: 0;
    display: inline-block;
    list-style: none;
    padding: 8px;
    margin: 2px;
    border-radius: 1em;
    background: black;
    color: white;
    font-size: small;
  }

  .tag-list > li > a {
    text-decoration: none;
    color: white;
  }
  
  .selected-tag {
    background: blue !important;
  }

  .lds-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #cef;
    border-color: #cef transparent #cef transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .composable-items {
    margin-top: 20px;
  }