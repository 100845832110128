/* chart */
.shape {
	/* fill-opacity: 0; */
    fill: none;
    stroke-width: 4px;
}

.shape:hover {
	fill-opacity: .65;
	z-index: 100;
}

.scale {
	fill: #FAFAFA;
	stroke: #999;
	stroke-width: .2
}

.axis {
	stroke: #555;
	stroke-width: .2
}

.dot {
  fill: white;
  stroke: #e7e8e7;
  r: 5;
  transition: r .2s;
}

.dot:hover {
  stroke: #bbb;
  r: 8;
}

.caption {
	fill: #444;
	font-weight: 400;
	text-shadow: 1px 1px 0 #fff;
    margin: 8px;
    font-size: 12px;
}

#survey svg {
    width: 100%;
    min-width: 200px;
}

.modular-thinking, .autonomy-thinking, .orchestration-thinking, .discovery-thinking {
    color: red!important;
}

.modular-architecture, .autonomy-architecture, .orchestration-architecture, .discovery-architecture {
    color: green!important;
}

.modular-technologies, .autonomy-technologies, .orchestration-technologies, .discovery-technologies {
    color: blue!important;
}