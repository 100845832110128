
.translation-text {
    background-color: lightgray;
    border: 2px solid darkgray;
    border-radius: 5px;
    font-weight: bold;
    padding: 4px;
    margin-bottom: 8px;
}

.translation-form > button {
    width: 100%;
    background-color: rgb(157, 100, 223);
    color: white;
    padding: 4px;
    font-size: larger;
    border: 2px solid rgb(112, 71, 159);
    border-radius: 10px;
}

button:hover {
    background-color: rgb(141, 69, 223);
}
