@font-face {
    font-family: "FujitsuInfinityPro-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/FujitsuInfinityPro-Bold.woff") format("woff"), url("../fonts/FujitsuInfinityPro-Bold.woff2") format("woff2");
  }
  
@font-face {
  font-family: "FujitsuInfinityPro-BoldItalic";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/FujitsuInfinityPro-BoldItalic.woff") format("woff"), url("../fonts/FujitsuInfinityPro-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "FujitsuInfinityPro-ExtraBold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/FujitsuInfinityPro-ExtraBold.woff") format("woff"), url("../fonts/FujitsuInfinityPro-ExtraBold.woff2") format("woff2");
}

@font-face {
font-family: "FujitsuInfinityPro-Light";
font-style: normal;
font-weight: 400;
src: url("../fonts/FujitsuInfinityPro-Light.woff") format("woff"), url("../fonts/FujitsuInfinityPro-Light.woff2") format("woff2");
}

@font-face {
  font-family: "FujitsuInfinityPro-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/FujitsuInfinityPro-Regular.woff") format("woff"), url("../fonts/FujitsuInfinityPro-Regular.woff2") format("woff2");
}

@font-face {
font-family: "FujitsuInfinityPro-RegularItalic";
font-style: normal;
font-weight: 400;
src: url("../fonts/FujitsuInfinityPro-RegularItalic.woff") format("woff"), url("../fonts/FujitsuInfinityPro-RegularItalic.woff2") format("woff2");
}
