.language-toggle {
    margin-right: 40px;
    margin-bottom: 24px;
    cursor: pointer;
}

.language-select {
    position: relative;
}

.flag-en {
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 12px;
}

.flag-jp {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.foreground-flag {
    z-index: 99;
}

.background-flag {
    z-index: 0;
}

.auth-link {
    min-width: 100px;
    font-size: small;
}