.composable-item {
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    color: white;
    float: left;
    margin: 5px 1%;
    padding: 0;
    max-width: 250px;
    min-width: 200px;
    height: 320px;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
}

.composable-item img {
    max-height: 320px;
    max-width: 100%;
    vertical-align: bottom;
}

.composable-item * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.composable-item h3 {
    display: inline-block;
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: -0.4px;
    margin: 0;
    padding: 0;
    
}

.composable-item .rating-wrapper {
    margin: 0;
    padding: 0;
    line-height: 0.5em;
}

.react-simple-star-rating {
    position: relative;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    touch-action: none;
  }
  
.fillIcons, .emptyIcons {
    position: absolute;
    top: 0;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    padding: 0;
}

.filled-icons, .empty-icons {
    padding: 0;
}

.composable-item > figure > figcaption {
    padding: 8px 8px 0px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    text-overflow: ellipsis;
    background-color: rgba(66, 66, 66, 0.635);
    z-index: 1;
}

.composable-item-description {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.25em;
}

.composable-item, .composable-item :hover {
    text-decoration: none;
    color: white;
}

.composable-item {
    border: white 2px solid;
}

.composable-item :hover {
    border-color: darkgray;
}

.number-of-reviews {
    margin-left: 8px;
    vertical-align: bottom;
}

.details {
    padding: 16px 0 32px 0;
}

.details-content {
    justify-content: left;
    text-align: left;
}

.react-flow {
    height: 300px !important;
}

.composable-list-item {
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    color: rgb(65, 65, 65);
    margin: 5px;
    padding: 4px;
    overflow: hidden;
    text-align: left;
    width: 100%;
}

.view-select {
    margin-bottom: 8px;
}
.view-select > .btn-group {
    display: block;
    padding-left: 0;
    margin: 0;
}
    
.composable-items-list {
    padding-top: 20px;
}

.composable-list-item img {
    width: 80px ;
    vertical-align: top;
    float: left;
}

.composable-list-item * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.composable-list-item-rating {
    margin-top: -4px;
    padding: 0;
}
.composable-list-item-main {
    display: inline-block;
    margin-left: 8px;
    width: 100%;
}
.composable-list-item-name {
    font-weight: 700;
    letter-spacing: -0.4px;
}

.composable-list-item-description {
    max-height: 6rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.composable-list-item, .composable-list-item :hover {
    text-decoration: none;
} 

.composable-list-item {
    border: white 2px solid;
    margin-left: 20px;
}

.composable-list-item :hover {
    border-color: darkgray;
}

.highlights-wrapper {
    border: solid 1px #ccc;
    padding: 8px 8px 0 0;
    border-radius: 8px;

}